//75
// const ProductCode = [
//   { 
//     id:75,
//     code: '105G', 
//     scheme_code: '105G', 
//     AMC: 'B', 
//     name: 'Aditya Birla Sun Life Balanced Advantage Fund - Growth' },
//   { 
//     id:74,
//     code: 'GLGPGGR', 
//     scheme_code: 'GLGPGGR', 
//     AMC: '166', 
//     name: 'Quant Multi Asset Fund - Regular Growth Plan-Growth' },
//   {
//     id: 73,
//     code: 'EOGPGR',
//     scheme_code: 'EOGPGR',
//     AMC: 'RMF',
//     name: 'NIPPON INDIA Multi Cap Fund - GROWTH PLAN GROWTH OPTION - GROWTH',
//   },
//   {
//     id: 72,
//     code: "DBGPGGR",
//     scheme_code: "DBGPGGR",
//     AMC: "128",
//     name: "Axis Dynamic Bond Fund -Regular Plan - Growth Option",
//   },
//   {
//     id: 71,
//     code: "USRGGGR",
//     scheme_code: "USRG",
//     AMC: "117",
//     name: "Mirae Asset Ultra Short Duration Fund Regular Growth",
//   },
//   {
//     id: 70,
//     code: "BFIGGGR",
//     scheme_code: "BFIG",
//     AMC: "108",
//     name: "UTI Low Duration Fund (Formerly UTI Treasury Advantage Fund) -REGULAR PLAN-GROWTH",
//   },
//   {
//     id: 70,
//     code: "100B",
//     scheme_code: "B100B",
//     AMC: "B",
//     name: "Aditya Birla Sun Life Silver ETF Fund of Fund Regular Growth",
//   },
//   {
//     id: 69,
//     code: "1525",
//     scheme_code: "P1525",
//     AMC: "P",
//     name: "ICICI Prudential Savings Fund - Growth",
//   },
//   {
//     id: 68,
//     code: "LDRG",
//     scheme_code: "HLDRG",
//     AMC: "H",
//     name: "HDFC Long Duration Debt Fund Regular Growth",
//   },
//   {
//     id: 67,
//     code: "1197",
//     scheme_code: "K1197",
//     AMC: "K",
//     name: "Kotak Multi Asset Allocation Fund Regular Plan - Growth",
//   },
//   {
//     id: 66,
//     code: "464G",
//     scheme_code: "L464G",
//     AMC: "L",
//     name: "SBI Banking & Financial Services Fund - Regular Plan-Growth",
//   },
//   {
//     id: 1,
//     code: "153G",
//     scheme_code: "B153G",
//     AMC: "B",
//     name: "Aditya Birla Sun Life Liquid Fund - Growth-Regular Plan (formerly known as Aditya Birla Sun Life Cash Plus)",
//   },
//   {
//     id: 50,
//     code: "205RG",
//     scheme_code: "B205RG",
//     AMC: "B",
//     name: "Aditya Birla Sun Life Arbitrage Fund - Growth-Regular Plan (formerly known as Aditya Birla Sun Life Enhanced Arbitrage Fund)",
//   },
//   {
//     id: 2,
//     code: "ATGPGGR",
//     scheme_code: "ATGP",
//     AMC: "118",
//     name: "Edelweiss Arbitrage Fund Growth Option",
//   },
//   {
//     id: 3,
//     code: "ARGPGGR",
//     scheme_code: "ARGP",
//     AMC: "118",
//     name: "Edelweiss Balanced Advantage Fund - Growth",
//   },
//   {
//     id: 4,
//     code: "EIRGGGR",
//     scheme_code: "EIRG",
//     AMC: "118",
//     name: "Edelweiss Equity Savings Fund - Regular Plan Growth",
//   },
//   {
//     id: 58,
//     code: "ENAGGGR",
//     scheme_code: "ENAG",
//     AMC: "118",
//     name: "Edelweiss Aggressive Hybrid Fund - Regular Plan - Growth",
//   },
//   {
//     id: 5,
//     code: "ILSGGGR",
//     scheme_code: "ILSG",
//     AMC: "118",
//     name: "Edelweiss Liquid Fund - Regular Growth",
//   },
//   {
//     id: 6,
//     code: "MYG05",
//     scheme_code: "HMYG05",
//     AMC: "H",
//     name: "HDFC Multi-Asset Fund - Regular Plan - Growth",
//   },
//   {
//     id: 7,
//     code: "02",
//     scheme_code: "H02",
//     AMC: "H",
//     name: "HDFC Flexi Cap Fund - Regular Plan - Growth",
//   },
//   {
//     id: 8,
//     code: "GFG",
//     scheme_code: "HGFG",
//     AMC: "H",
//     name: "HDFC Balanced Advantage Fund - Regular Plan - Growth",
//   },
//   {
//     id: 9,
//     code: "AFWG",
//     scheme_code: "HAFWG",
//     AMC: "H",
//     name: "HDFC Arbitrage Fund - Wholesale Plan - Regular Plan - Growth",
//   },
//   {
//     id: 10,
//     code: "HLFGN",
//     scheme_code: "HLFGN",
//     AMC: "H",
//     name: "HDFC Liquid Fund - Regular Plan - Growth",
//   },
//   {
//     id: 11,
//     code: "54",
//     scheme_code: "H54",
//     AMC: "H",
//     name: "HDFC Low Duration Fund - Regular Plan - Growth",
//   },
//   {
//     id: 12,
//     code: "57N",
//     scheme_code: "H57N",
//     AMC: "H",
//     name: "HDFC Overnight Fund - Regular Plan -  Growth",
//   },
//   {
//     id: 13,
//     code: "USTGR",
//     scheme_code: "HUSTGR",
//     AMC: "H",
//     name: "HDFC Ultra Short Term Fund - Regular Growth",
//   },
//   {
//     id: 14,
//     code: "INNP",
//     scheme_code: "HINNP",
//     AMC: "H",
//     name: "HDFC Index Fund-NIFTY 50 Plan - Regular Plan Growth ",
//   },
//   {
//     id: 52,
//     code: "MULG",
//     scheme_code: "HMULG",
//     AMC: "H",
//     name: "HDFC Equity Savings Fund - Regular Plan - Growth",
//   },
//   {
//     id: 15,
//     code: "EDIRG",
//     scheme_code: "PEDIRG",
//     AMC: "P",
//     name: "ICICI Prudential Equity Arbitrage Fund - Growth",
//   },
//   {
//     id: 16,
//     code: "EDWRG",
//     scheme_code: "PEDWRG",
//     AMC: "P",
//     name: "ICICI Prudential Balanced Advantage Fund - Growth",
//   },
//   {
//     id: 17,
//     code: "1565",
//     scheme_code: "P1565",
//     AMC: "P",
//     name: "ICICI Prudential Liquid Fund - Regular plan - Growth",
//   },
//   {
//     id: 18,
//     code: "15",
//     scheme_code: "P15",
//     AMC: "P",
//     name: "ICICI Prudential Multi-Asset Fund - Growth",
//   },
//   {
//     id: 51,
//     code: "3190",
//     scheme_code: "P3190",
//     AMC: "P",
//     name: "ICICI Prudential Manufacturing Fund Growth",
//   },
//   {
//     id: 19,
//     code: "2556",
//     scheme_code: "P2556",
//     AMC: "P",
//     name: "ICICI Prudential Equity Savings Fund Growth",
//   },
//   {
//     id: 20,
//     code: "3491",
//     scheme_code: "P3491",
//     AMC: "P",
//     name: "ICICI Prudential Overnight Fund Growth",
//   },
//   {
//     id: 21,
//     code: "1746",
//     scheme_code: "P1746",
//     AMC: "P",
//     name: "ICICI Prudential Ultra Short Term Fund - Growth",
//   },
//   {
//     id: 55,
//     code: "61",
//     scheme_code: "P61",
//     AMC: "P",
//     name: "ICICI Prudential Equity & Debt Fund - Growth",
//   },
//   {
//     id: 64,
//     code: "BFRGGGR",
//     scheme_code: "BFRG",
//     AMC: "120",
//     name: "Invesco India Financial Services Fund - Growth",
//   },
//   {
//     id: 66,
//     code: "197",
//     scheme_code: "K197",
//     AMC: "K",
//     name: "Kotak Dynamic Bond Fund Regular Plan Growth (Erstwhile Kotak Flexi Debt)",
//   },
//   {
//     id: 23,
//     code: "35",
//     scheme_code: "K35",
//     AMC: "K",
//     name: "Kotak Equity Savings Fund - Growth (Regular Plan)",
//   },
//   {
//     id: 24,
//     code: "179",
//     scheme_code: "K179",
//     AMC: "K",
//     name: "Kotak Equity Arbitrage Fund - Growth (Regular Plan)",
//   },
//   {
//     id: 22,
//     code: "470",
//     scheme_code: "K470",
//     AMC: "K",
//     name: "Kotak Liquid fund Regular Plan Growth",
//   },
//   {
//     id: 56,
//     code: "496",
//     scheme_code: "K496",
//     AMC: "K",
//     name: "KOTAK NIFTY 50 INDEX FUND-REGULAR PLAN-GROWTH",
//   },
//   {
//     id: 25,
//     code: "LFGPGGR",
//     scheme_code: "LFGP",
//     AMC: "127",
//     name: "Motilal Oswal Liquid Fund - Regular Growth",
//   },
//   {
//     id: 26,
//     code: "USGPGGR",
//     scheme_code: "USGP",
//     AMC: "127",
//     name: "Motilal Oswal Ultra Short Term Fund - Growth",
//   },
//   {
//     id: 27,
//     code: "AFGPGR",
//     scheme_code: "AFGP",
//     AMC: "RMF",
//     name: "NIPPON INDIA Arbitrage Fund - GROWTH PLAN - GROWTH",
//   },
//   {
//     id: 28,
//     code: "LFIGGR",
//     scheme_code: "LFIG",
//     AMC: "RMF",
//     name: "NIPPON INDIA LIQUID FUND - GROWTH PLAN - GROWTH OPTION",
//   },
//   {
//     id: 29,
//     code: "LPIGGR",
//     scheme_code: "LPIG",
//     AMC: "RMF",
//     name: "NIPPON INDIA Low Duration Fund - Growth Plan Growth Option",
//   },
//   {
//     id: 30,
//     code: "NEGPGR",
//     scheme_code: "NEGP",
//     AMC: "RMF",
//     name: "NIPPON INDIA Balanced Advantage Fund - GROWTH PLAN - GROWTH OPTION - GROWTH",
//   },
//   {
//     id: 31,
//     code: "MFGPGR",
//     scheme_code: "MFGP",
//     AMC: "RMF",
//     name: "NIPPON INDIA MULTI ASSET FUND - REGULAR - GROWTH",
//   },
//   {
//     id: 32,
//     code: "ONGPGR",
//     scheme_code: "ONGP",
//     AMC: "RMF",
//     name: "NIPPON INDIA OVERNIGHT FUND - GROWTH PLAN",
//   },
//   {
//     id: 33,
//     code: "CPGPGR",
//     scheme_code: "CPGP",
//     AMC: "RMF",
//     name: "NIPPON INDIA Ultra Short Duration Fund - Growth Option",
//   },
//   {
//     id: 53,
//     code: "EARGGR",
//     scheme_code: "EARG",
//     AMC: "RMF",
//     name: "NIPPON INDIA Large Cap Fund -  GROWTH PLAN - GROWTH",
//   },
//   {
//     id: 54,
//     code: "BFGPGR",
//     scheme_code: "BFGP",
//     AMC: "RMF",
//     name: "Nippon India Banking & Financial Services Fund -  GROWTH PLAN- GROWTH OPTION",
//   },
//   {
//     id: 34,
//     code: "PP001RG",
//     scheme_code: "PP001RG",
//     AMC: "PP",
//     name: "Parag Parikh Flexi Cap Fund - Regular Plan - Growth",
//   },
//   {
//     id: 35,
//     code: "PEGPGGR",
//     scheme_code: "PEGP",
//     AMC: "166",
//     name: "Quant Flexi Cap Fund - Regular Growth Plan-Growth",
//   },
//   {
//     id: 65,
//     code: "GLGPGGR",
//     scheme_code: "GLGP",
//     AMC: "166",
//     name: "Quant Multi Asset Fund (Formerly know as Quant Unconstrained Fund) - Regular Plan Growth",
//   },
//   {
//     id: 36,
//     code: "LFGPGGR",
//     scheme_code: "LFGP",
//     AMC: "166",
//     name: "Quant Liquid Plan-Regular Growth Plan-Growth",
//   },
//   {
//     id: 37,
//     code: "OFGPGGR",
//     scheme_code: "OFGP",
//     AMC: "166",
//     name: "Quant Mid Cap Fund - Regular Growth Plan-Growth",
//   },
//   {
//     id: 38,
//     code: "IBGPGGR",
//     scheme_code: "IBGP",
//     AMC: "166",
//     name: "Quant Small Cap Fund - Regular Growth Plan-Growth",
//   },

//   {
//     id: 39,
//     code: "114G",
//     scheme_code: "L114G",
//     AMC: "L",
//     name: "SBI Arbitrage Opportunities Fund - Regular Plan - Growth",
//   },
//   {
//     id: 40,
//     code: "473G",
//     scheme_code: "L473G",
//     AMC: "L",
//     name: "SBI Equity Savings Fund - Regular Plan-Growth",
//   },
//   {
//     id: 41,
//     code: "638G",
//     scheme_code: "L638G",
//     AMC: "L",
//     name: "SBI Balanced Advantage Fund - Regular Growth",
//   },
//   {
//     id: 65,
//     code: "074G",
//     scheme_code: "L074G",
//     AMC: "L",
//     name: "SBI Dynamic Bond Fund - Regular Plan - Growth",
//   },
//   {
//     id: 42,
//     code: "72SG",
//     scheme_code: "L72SG",
//     AMC: "L",
//     name: "SBI Liquid Fund Regular Growth",
//   },
//   {
//     id: 43,
//     code: "F47RG",
//     scheme_code: "LF47RG",
//     AMC: "L",
//     name: "SBI Magnum Low Duration Fund Regular Growth",
//   },
//   {
//     id: 44,
//     code: "086G",
//     scheme_code: "L086G",
//     AMC: "L",
//     name: "SBI Magnum Ultra Short Duration Fund Regular Growth",
//   },
//   {
//     id: 45,
//     code: "57G",
//     scheme_code: "L57G",
//     AMC: "L",
//     name: "SBI Overnight Fund Regular Growth",
//   },
//   {
//     id: 46,
//     code: "MONG",
//     scheme_code: "TMONG",
//     AMC: "T",
//     name: "TATA Liquid Fund Regular Plan - Growth",
//   },
//   {
//     id: 47,
//     code: "AFG",
//     scheme_code: "TAFG",
//     AMC: "T",
//     name: "Tata Arbitrage Fund-Regular Plan-Growth",
//   },
//   {
//     id: 48,
//     code: "BAFG",
//     scheme_code: "TBAFG",
//     AMC: "T",
//     name: "Tata Balanced Advantage Fund - Regular Plan - Growth",
//   },
//   {
//     id: 49,
//     code: "IFMG",
//     scheme_code: "TIFMG",
//     AMC: "T",
//     name: "Tata Equity Savings Fund Regular Plan - Growth",
//   },
//   {
//     id: 57,
//     code: "DIFG",
//     scheme_code: "TDIFG",
//     AMC: "T",
//     name: "Tata Digital India Fund Regular Plan Growth",
//   },
//   {
//     id: 59,
//     code: "CPIGGGR",
//     scheme_code: "CPIG",
//     AMC: "108",
//     name: "UTI Liquid Fund (Formerly UTI Liquid Cash Plan) -REGULAR PLAN-GROWTH",
//   },
//   {
//     id: 60,
//     code: "FBGPGGR",
//     scheme_code: "FBGP",
//     AMC: "108",
//     name: "UTI Balance Advantage Fund - Regular Plan - Growth Option",
//   },
//   {
//     id: 61,
//     code: "W2GPGGR",
//     scheme_code: "W2GP",
//     AMC: "108",
//     name: "UTI Multi Asset Allocation Fund (Formerly UTI Multi Asset Fund) -REGULAR PLAN-GROWTH",
//   },
//   {
//     id: 62,
//     code: "ESGPGGR",
//     scheme_code: "ESGP",
//     AMC: "108",
//     name: "UTI Equity Savings Fund-Regular Growth Plan-Growth",
//   },
//   {
//     id: 63,
//     code: "FSGPGGR",
//     scheme_code: "FSGP",
//     AMC: "108",
//     name: "UTI Ultra Short Duration Fund (Formerly UTI Ultra Short Term Fund) -REGULAR PLAN-GROWTH",
//   },
//   {
//     id: 100,
//     code: "92",
//     scheme_code: "FSGP",
//     AMC: "B",
//     name: "Aditya Birla SL Frontline Equity Fund(G)",
//   },

// ];

const ProductCode = [
  {
    id: 1,
    code: 'RMFONGP',
    scheme_code: 'ONGP',
    AMC: 'RMF',
    name: 'NIPPON INDIA OVERNIGHT FUND - GROWTH PLAN'
  },
  {
    id: 2,
    code: 'HLFGN',
    scheme_code: 'HLFGN',
    AMC: 'H',
    name: 'HDFC Liquid Fund - Regular Plan - Growth'
  },
  {
    id: 3,
    code: 'RMFAFGP',
    scheme_code: 'AFGP',
    AMC: 'RMF',
    name: 'NIPPON INDIA ARBITRAGE FUND - GROWTH PLAN'
  },
  {
    id: 4,
    code: '166LFGP',
    scheme_code: 'LFGP',
    AMC: '166',
    name: 'Quant Liquid Fund - Regular Plan'
  },
  {
    id: 5,
    code: 'P1746',
    scheme_code: 'P1746',
    AMC: 'P',
    name: 'ICICI Prudential Ultra Short Term Fund - Growth'
  },
  {
    id: 6,
    code: 'K470',
    scheme_code: 'K470',
    AMC: 'K',
    name: 'Kotak Liquid Fund Regular Plan Growth'
  },
  {
    id: 7,
    code: 'P1565',
    scheme_code: 'P1565',
    AMC: 'P',
    name: 'ICICI Prudential Liquid Fund - Growth'
  },
  {
    id: 8,
    code: 'HMULG',
    scheme_code: 'HMULG',
    AMC: 'H',
    name: 'HDFC Equity Savings Fund - Regular Plan - Growth'
  },
  {
    id: 9,
    code: 'TAFG',
    scheme_code: 'TAFG',
    AMC: 'T',
    name: 'Tata Arbitrage Fund-Regular Plan-Growth'
  },
  {
    id: 10,
    code: 'L57G',
    scheme_code: 'L57G',
    AMC: 'L',
    name: 'SBI Overnight Fund Regular Growth'
  },
  {
    id: 11,
    code: '118ATGP',
    scheme_code: 'ATGP',
    AMC: '118',
    name: 'Edelweiss Arbitrage Fund - Regular Plan Growth'
  },
  {
    id: 12,
    code: '118ARGP',
    scheme_code: 'ARGP',
    AMC: '118',
    name: 'Edelweiss Balanced Advantage Fund - Regular Growth'
  },
  {
    id: 13,
    code: 'RMFLFIG',
    scheme_code: 'LFIG',
    AMC: 'RMF',
    name: 'NIPPON INDIA LIQUID FUND - GROWTH PLAN - GROWTH OPTION'
  },
  {
    id: 14,
    code: 'RMFNEGP',
    scheme_code: 'NEGP',
    AMC: 'RMF',
    name: 'NIPPON INDIA BALANCED ADVANTAGE FUND - GROWTH PLAN - GROWTH OPTION'
  },
  {
    id: 15,
    code: 'L114G',
    scheme_code: 'L114G',
    AMC: 'L',
    name: 'SBI Arbitrage Opportunities Fund - Regular Plan - Growth'
  },
  {
    id: 16,
    code: 'PEDWRG',
    scheme_code: 'PEDWRG',
    AMC: 'P',
    name: 'ICICI Prudential Balanced Advantage Fund - Growth'
  },
  {
    id: 17,
    code: 'H02',
    scheme_code: 'H02',
    AMC: 'H',
    name: 'HDFC Flexi Cap Fund - Regular Plan - Growth'
  },
  {
    id: 18,
    code: 'P3190',
    scheme_code: 'P3190',
    AMC: 'P',
    name: 'ICICI Prudential Manufacturing Fund Growth'
  },
  {
    id: 19,
    code: 'L638G',
    scheme_code: 'L638G',
    AMC: 'L',
    name: 'SBI Balanced Advantage Fund - Regular Growth'
  },
  {
    id: 20,
    code: 'TDIFG',
    scheme_code: 'TDIFG',
    AMC: 'T',
    name: 'Tata Digital India Fund Regular Plan Growth'
  },
  {
    id: 21,
    code: 'TMONG',
    scheme_code: 'TMONG',
    AMC: 'T',
    name: 'Tata Liquid Fund Regular Plan - Growth'
  },
  {
    id: 22,
    code: 'HINNP',
    scheme_code: 'HINNP',
    AMC: 'H',
    name: 'HDFC Index Fund-NIFTY 50 Plan - Regular Plan'
  },
  {
    id: 23,
    code: 'L72SG',
    scheme_code: 'L72SG',
    AMC: 'L',
    name: 'SBI Liquid Fund Regular Growth'
  },
  {
    id: 24,
    code: 'PEDIRG',
    scheme_code: 'PEDIRG',
    AMC: 'P',
    name: 'ICICI Prudential Equity Arbitrage Fund - Growth'
  },
  {
    id: 25,
    code: 'B153G',
    scheme_code: 'B153G',
    AMC: 'B',
    name: 'Aditya Birla Sun Life Liquid Fund - Growth-Regular Plan (formerly known as Aditya Birla Sun Life Cas'
  },
  {
    id: 26,
    code: 'TBAFG',
    scheme_code: 'TBAFG',
    AMC: 'T',
    name: 'Tata Balanced Advantage Fund - Regular Plan - Growth'
  },
  {
    id: 27,
    code: '120BFRG',
    scheme_code: 'BFRG',
    AMC: '120',
    name: 'Invesco India Financial Services Fund - Regular Growth'
  },
  {
    id: 28,
    code: 'K496',
    scheme_code: 'K496',
    AMC: 'K',
    name: 'Kotak Nifty 50 Index Fund Growth (Regular Plan)'
  },
  {
    id: 29,
    code: 'P61',
    scheme_code: 'P61',
    AMC: 'P',
    name: 'ICICI Prudential Equity & Debt Fund - Growth'
  },
  {
    id: 30,
    code: '108W2GP',
    scheme_code: 'W2GP',
    AMC: '108',
    name: 'UTI Multi Asset Allocation Fund - Regular Plan'
  },
  {
    id: 31,
    code: 'HGFG',
    scheme_code: 'HGFG',
    AMC: 'H',
    name: 'HDFC Balanced Advantage Fund - Regular Plan - Growth'
  },
  {
    id: 32,
    code: 'H54',
    scheme_code: 'H54',
    AMC: 'H',
    name: 'HDFC Low Duration Fund - Regular Plan - Growth'
  },
  {
    id: 33,
    code: '166PEGP',
    scheme_code: 'PEGP',
    AMC: '166',
    name: 'Quant Flexi Cap Fund - Regular Plan Growth'
  },
  {
    id: 34,
    code: 'L473G',
    scheme_code: 'L473G',
    AMC: 'L',
    name: 'SBI Equity Savings Fund - Regular Plan-Growth'
  },
  {
    id: 35,
    code: 'RMFMFGP',
    scheme_code: 'MFGP',
    AMC: 'RMF',
    name: 'NIPPON INDIA MULTI ASSET FUND - GROWTH PLAN'
  },
  {
    id: 36,
    code: 'HMYG05',
    scheme_code: 'HMYG05',
    AMC: 'H',
    name: 'HDFC Multi-Asset Fund - Regular Plan - Growth'
  },
  {
    id: 37,
    code: '108CPIG',
    scheme_code: 'CPIG',
    AMC: '108',
    name: 'UTI Liquid Fund - Regular Plan'
  },
  {
    id: 38,
    code: '118ILSG',
    scheme_code: 'ILSG',
    AMC: '118',
    name: 'Edelweiss Liquid Fund - Regular Plan Growth'
  },
  {
    id: 39,
    code: 'H57N',
    scheme_code: 'H57N',
    AMC: 'H',
    name: 'HDFC Overnight Fund - Regular Plan -  Growth'
  },
  {
    id: 40,
    code: 'K179',
    scheme_code: 'K179',
    AMC: 'K',
    name: 'Kotak Equity Arbitrage Fund - Growth (Regular Plan)'
  },
  {
    id: 41,
    code: 'RMFLPIG',
    scheme_code: 'LPIG',
    AMC: 'RMF',
    name: 'NIPPON INDIA LOW DURATION FUND - GROWTH PLAN GROWTH OPTION'
  },
  {
    id: 42,
    code: '118EIRG',
    scheme_code: 'EIRG',
    AMC: '118',
    name: 'Edelweiss Equity Savings Fund - Regular Plan Growth'
  },
  {
    id: 43,
    code: 'K35',
    scheme_code: 'K35',
    AMC: 'K',
    name: 'Kotak Equity Savings Fund - Growth (Regular Plan)'
  },
  {
    id: 44,
    code: 'PP001RG',
    scheme_code: 'PP001RG',
    AMC: 'PP',
    name: 'Parag Parikh Flexi Cap Fund - Regular Plan'
  },
  {
    id: 45,
    code: 'P15',
    scheme_code: 'P15',
    AMC: 'P',
    name: 'ICICI Prudential Multi-Asset Fund - Growth'
  },
  {
    id: 46,
    code: 'B205RG',
    scheme_code: 'B205RG',
    AMC: 'B',
    name: 'Aditya Birla Sun Life Arbitrage Fund - Growth-Regular Plan (formerly known as Aditya Birla Sun Life'
  },
  {
    id: 47,
    code: 'RMFEOGP',
    scheme_code: 'EOGP',
    AMC: 'RMF',
    name: 'NIPPON INDIA MULTI CAP FUND - GROWTH PLAN GROWTH OPTION'
  },
  {
    id: 48,
    code: 'RMFBFGP',
    scheme_code: 'BFGP',
    AMC: 'RMF',
    name: 'NIPPON INDIA BANKING & FINANCIAL SERVICES FUND - GROWTH PLAN'
  },
  {
    id: 49,
    code: '118ENAG',
    scheme_code: 'ENAG',
    AMC: '118',
    name: 'Edelweiss Aggressive Hybrid Fund - Regular Plan Growth'
  },
  {
    id: 50,
    code: 'RMFEARG',
    scheme_code: 'EARG',
    AMC: 'RMF',
    name: 'NIPPON INDIA LARGE CAP FUND - GROWTH PLAN GROWTH OPTION'
  },
  {
    id: 51,
    code: 'L074G',
    scheme_code: 'L074G',
    AMC: 'L',
    name: 'SBI Dynamic Bond Fund - Regular Plan - Growth'
  },
  {
    id: 52,
    code: 'K197',
    scheme_code: 'K197',
    AMC: 'K',
    name: 'Kotak Dynamic Bond Fund Regular Plan Growth (Erstwhile Kotak Flexi Debt)'
  },
  {
    id: 53,
    code: 'L464G',
    scheme_code: 'L464G',
    AMC: 'L',
    name: 'SBI Banking & Financial Services Fund - Regular Plan-Growth'
  },
  {
    id: 54,
    code: 'K1197',
    scheme_code: 'K1197',
    AMC: 'K',
    name: 'Kotak Multi Asset Allocation Fund Regular Plan - Growth'
  },
  {
    id: 55,
    code: 'HHLDRG',
    scheme_code: 'HHLDRG',
    AMC: 'H',
    name: 'HDFC Long Duration Debt Fund Regular Growth'
  },
  {
    id: 56,
    code: 'B100B',
    scheme_code: 'B100B',
    AMC: 'B',
    name: 'Aditya Birla Sun Life Silver ETF Fund of Fund Regular Growth'
  },
  {
    id: 57,
    code: '166GLGP',
    scheme_code: 'GLGP',
    AMC: '166',
    name: 'Quant Multi Asset Fund (Formerly Know As Quant Unconstrained Fund) - Regular Plan Growth'
  },
  {
    id: 58,
    code: '108BFIG',
    scheme_code: 'BFIG',
    AMC: '108',
    name: 'UTI Low Duration Fund - Regular Plan'
  },
  {
    id: 59,
    code: '117USRG',
    scheme_code: 'USRG',
    AMC: '117',
    name: 'Mirae Asset Ultra Short Duration Fund - Regular Plan'
  },
  {
    id: 60,
    code: 'RMFCPGP',
    scheme_code: 'CPGP',
    AMC: 'RMF',
    name: 'NIPPON INDIA ULTRA SHORT DURATION FUND - GROWTH OPTION - GROWTH PLAN'
  },
  {
    id: 61,
    code: 'P1525',
    scheme_code: 'P1525',
    AMC: 'P',
    name: 'ICICI Prudential Savings Fund - Growth'
  },
  {
    id: 62,
    code: 'P2556',
    scheme_code: 'P2556',
    AMC: 'P',
    name: 'ICICI Prudential Equity Savings Fund Cumulative'
  },
  {
    id: 63,
    code: '128DBGP',
    scheme_code: 'DBGP',
    AMC: '128',
    name: 'Axis Dynamic Bond Fund - Regular Growth'
  },
  {
    id: 64,
    code: 'B105G',
    scheme_code: 'B105G',
    AMC: 'B',
    name: 'Aditya Birla Sun Life Balanced Advantage Fund - Growth'
  },
  {
    id: 65,
    code: 'B132G',
    scheme_code: 'B132G',
    AMC: 'B',
    name: 'Aditya Birla Sun Life Savings Fund - Growth-Regular Plan'
  },
  {
    id: 66,
    code: '108ESGP',
    scheme_code: 'ESGP',
    AMC: '108',
    name: 'UTI Equity Savings Fund - Regular Plan'
  },
  {
    id: 67,
    code: '118UTRG',
    scheme_code: 'UTRG',
    AMC: '118',
    name: 'Edelweiss US Technology Equity Fund Of Fund - Regular Plan Growth'
  },
  {
    id: 68,
    code: 'RMFMEGP',
    scheme_code: 'MEGP',
    AMC: 'RMF',
    name: 'NIPPON INDIA CONSUMPTION FUND - GROWTH PLAN - GROWTH OPTION'
  },
  {
    id: 69,
    code: 'L079B',
    scheme_code: 'L079B',
    AMC: 'L',
    name: 'SBI Savings Fund - Regular Plan - Growth'
  },
  {
    id: 70,
    code: 'L035G',
    scheme_code: 'L035G',
    AMC: 'L',
    name: 'SBI Healthcare Opportunities Fund Regular Growth'
  }
]

const BankName = [
  { code: "AUB", title: "AU Small Finance Bank" },
  { code: "AXC", title: "Axis Bank Corporate" },
  { code: "UTI", title: "Axis Bank Ltd" },
  { code: "BNPP", title: "BNP Paribas Bank" },
  { code: "BDN", title: "Bandhan Bank" },
  { code: "BBC", title: "Bank of Baroda - Corporate Banking" },
  { code: "BBR", title: "Bank of Baroda - Retail Banking" },
  { code: "BOI", title: "Bank of India" },
  { code: "BOM", title: "Bank of Maharashtra" },
  { code: "CIT", title: "CITI BANK" },
  { code: "CNB", title: "Canara Bank" },
  { code: "CSBF", title: "Capital Small Bank Finance Ltd" },
  { code: "CBI", title: "Central Bank of India" },
  { code: "CUB", title: "City Union Bank" },
  { code: "DBK", title: "Deutsche Bank" },
  { code: "EQB", title: "Equitas Small Finance Bank" },
  { code: "FBK", title: "Federal Bank" },
  { code: "HDF", title: "HDFC Bank Ltd" },
  { code: "HSB", title: "HSBC" },
  { code: "ICI", title: "ICICI Bank Ltd" },
  { code: "ICO", title: "ICICI Bank Ltd - Corporate" },
  { code: "IDB", title: "IDBI Bank" },
  { code: "IDC", title: "IDBI Corporate" },
  { code: "IDN", title: "IDFC First Bank" },
  { code: "INB", title: "INDIAN BANK" },
  { code: "IDS", title: "IndusInd Bank" },
  { code: "KBL", title: "Karnataka Bank Ltd" },
  { code: "KVB", title: "Karur Vysya Bank" },
  { code: "162", title: "Kotak Bank" },
  { code: "CPN", title: "Punjab National Bank - Corporate Banking" },
  { code: "PNB", title: "Punjab National Bank - Retail Banking" },
  { code: "RBL", title: "Ratnakar Bank" },
  { code: "RTC", title: "Ratnakar Corporate Banking" },
  { code: "SIB", title: "South Indian Bank" },
  { code: "SCB", title: "Standard Chartered Bank" },
  { code: "SBI", title: "State Bank of India" },
  { code: "SBOM", title: "State Bank of Mauritius Ltd" },
  { code: "UBI", title: "Union Bank of India" },
  { code: "YBK", title: "Yes Bank Ltd" },
];

const PaymentMode = [
  { code: "M", name: "Debit Mandate" },
  { code: "OL", name: "Online" },
  { code: "TR", name: "RTGS / NEFT" },
  { code: "UPI", name: "UPI" },
];

//new Date().toLocaleDateString() -> '27/09/2023' 2023-09-29T23:59:59.000Z
const PublicHolidays = [
  {
    id: 1,
    date: "2025-02-25T23:59:59.000Z",
    Day: "Wednesday",
    Desc: "Mahashivratri",
  },
  {
    id: 2,
    date: "2025-03-13T23:59:59.000Z",
    Day: "Friday",
    Desc: "Holi",
  },
  {
    id: 3,
    date: "2025-03-30T23:59:59.000Z",
    Day: "Monday",
    Desc: "Id-UI-Fir (Ramadan Eid)",
  },
  {
    id: 4,
    date: "2025-04-09T23:59:59.000Z",
    Day: "Thursday",
    Desc: "Shri Mahavir Jayanti",
  },
  {
    id: 5,
    date: "2025-04-13T23:59:59.000Z",
    Day: "Monday",
    Desc: "Dr. babasaheb Ambedkar Jayanti",
  },
  {
    id: 6,
    date: "2025-04-17T23:59:59.000Z",
    Day: "Friday",
    Desc: "Good Friday",
  },
  {
    id: 7,
    date: "2025-04-30T23:59:59.000Z",
    Day: "Thursday",
    Desc: "Maharastra Day",
  },
  {
    id: 8,
    date: "2025-08-14T23:59:59.000Z",
    Day: "Friday",
    Desc: "Independence Day",
  },
  {
    id: 9,
    date: "2025-08-26T23:59:59.000Z",
    Day: "Wednesday",
    Desc: "Ganesh Chaturthi",
  },
  {
    id: 10,
    date: "2025-10-01T23:59:59.000Z",
    Day: "Thursday",
    Desc: "Mahatma Gandhi Jayanti",
  },
  {
    id: 11,
    date: "2025-10-20T23:59:59.000Z",
    Day: "Tuesday",
    Desc: "Diwali-Laxmi Pujan",
  },
  {
    id: 12,
    date: "2025-10-21T23:59:59.000Z",
    Day: "Wednesday",
    Desc: "Baliparatipada",
  },
  {
    id: 13,
    date: "2025-11-04T23:59:59.000Z",
    Day: "Wednesday",
    Desc: "Prakash Gurpurb Shri Guru Nanak Dev",
  },
  {
    id: 14,
    date: "2025-12-24T23:59:59.000Z",
    Day: "Thursday",
    Desc: "Christmas",
  },
];

const STATE = [
  { code: "AN", value: "Andaman and Nicobar Islands" },
  { code: "AP", value: "Andhra Pradesh" },
  { code: "AR", value: "Arunachal Pradesh" },
  { code: "AS", value: "Assam" },
  { code: "BH", value: "Bihar" },
  { code: "CH", value: "Chandigarh" },
  { code: "CG", value: "Chhattisgarh" },
  { code: "DN", value: "Dadra and Nagar Haveli" },
  { code: "DD", value: "Daman and Diu" },
  { code: "GO", value: "Goa" },
  { code: "GU", value: "Gujarat" },
  { code: "HA", value: "Haryana" },
  { code: "HP", value: "Himachal Pradesh" },
  { code: "KR", value: "Jammu and Kashmir" },
  { code: "JD", value: "Jharkhand" },
  { code: "KA", value: "Karnataka" },
  { code: "KE", value: "Kerala" },
  { code: "LD", value: "Lakshadweep" },
  { code: "MP", value: "Madhya Pradesh" },
  { code: "MA", value: "Maharashtra" },
  { code: "MN", value: "Manipur" },
  { code: "ME", value: "Meghalaya" },
  { code: "MI", value: "Mizoram" },
  { code: "NA", value: "Nagaland" },
  { code: "ND", value: "New Delhi" },
  { code: "OD", value: "ODISHA" },
  { code: "OT", value: "Others" },
  { code: "PO", value: "Puducherry" },
  { code: "PU", value: "Punjab" },
  { code: "RA", value: "Rajasthan" },
  { code: "SI", value: "Sikkim" },
  { code: "TN", value: "Tamil Nadu" },
  { code: "TE", value: "Telangana" },
  { code: "TR", value: "Tripura" },
  { code: "UP", value: "Uttar Pradesh" },
  { code: "UR", value: "Uttarakhand" },
  { code: "WB", value: "West Bengal" },
];

const dayNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const date = new Date().toISOString();
const Name_Days = dayNames[new Date().getDay()];
const isholiday = PublicHolidays.filter(
  (ele) => new Date(ele.date).getTime() > new Date(date).getTime()
)[0];


const redemptionVariables = /Redemption|Lateral Shift Out|Switchout/;
const purchaseVariables =  /Purchase|Systematic Investment|Systematic-NSE|Lateral Shift In|Switch-In/;


const FundData = [
  {
    AMC:"H",
    name:"HDFC Mutual Fund"
  },
  {
    AMC:"RMF",
    name:"Nippon India Mutual Fund"
  },
  {
    AMC:"L",
    name:"SBI Mutual Fund"
  },
  {
    AMC:"P",
    name:"ICICI Prudential Mutual Fund"
  },
  {
    AMC:"127",
    name:"Motilal Oswal Mutual Fund"
  },
  {
    AMC:"166",
    name:"Quant Mutual Fund"
  },
  {
    AMC:"PP",
    name:"Parag Parikh Mutual Fund"
  },
  {
    AMC:"K",
    name:"Kotak Mahindra Mutual Fund"
  },
  {
    AMC:"T",
    name:"Tata Mutual Fund"
  },
  {
    AMC:"118",
    name:"Edelweiss Mutual Fund"
  },
  {
    AMC:"B",
    name:"Aditya Birla Mutual Fund"
  },
  {
    AMC:"108",
    name:"UTI Mutual Fund"
  },
  {
    AMC:"120",
    name:"Invesco Mutual Fund"
  },
    {
    AMC:"128",
    name:"AXIS Mutual Fund"
  },
    {
    AMC:"101",
    name:"Canara Robeco Mutual Fund"
  },
    {
    AMC:"117",
    name:"Mirae Mutual Fund"
  },
  {
    AMC:"105",
    name:"JM Financial Services Limited Mutual Fund"
  },
]

const equity = [
  { id: 1, name: "Large Cap Funds" },
  { id: 2, name: "Multi Cap" },
  { id: 3, name: "Flexi Cap" },
  { id: 4, name: "Large & Mid Cap" },
  { id: 5, name: "Mid Cap Funds" },
  { id: 6, name: "Small Cap" },
  { id: 7, name: "Focused" },
  { id: 8, name: "Sectoral -Thematic" },
  { id: 9, name: "ELSS" },
  { id: 10, name: "Dividend Yield" },
  { id: 11, name: "Value" },
  { id: 12, name: "Contra" },
];

const debt_funds = [
  'Overnight',
  'Liquid',
  'Ultra Short Fund',
  'Low Duration',
  'Short Duration',
  'Medium Duration',
  'Medium to Long Dur',
  'Long Duration',
  'Dynamic Bond',
  'Credit Risk Fund',
  'Gilt',
  'Banking and PSU Fund',
  'Corporate Bond',
  'Floating Rate',
  'Money Market',
];

const hybrid_funds = [
  'Arbitrage',
  'Equity Savings',
  'Hybrid - Conservative',
  'Multi Asset Allocation',
  'Hybrid - Aggressive',
  'Dyn Asset Alloc & Balance Adv',
];

const debit= [
  { id: 1, name: "Overnight" },
  { id: 2, name: "Liquid" },
  { id: 3, name: "Ultra Short Fund" },
  { id: 4, name: "Low Duration" },
  { id: 5, name: "Short Duration" },
  { id: 6, name: "Medium Duration" },
  { id: 7, name: "Medium to Long Dur" },
  { id: 8, name: "Long Duration" },
  { id: 9, name: "Dynamic Bond" },
  { id: 10, name: "Credit Risk Fund" },
  { id: 11, name: "Gilt" },
  { id: 12, name: "Banking and PSU Fund" },
  { id: 13, name: "Corporate Bond" },
  { id: 14, name: "Floating Rate" },
  { id: 15, name: "Money Market" },
];

const hybrid = [
  { id: 1, name: "Arbitrage" },
  { id: 2, name: "Equity Savings" },
  { id: 3, name: "Multi Asset Allocation" },
  { id: 4, name: "Hybrid - Conservative" },
  { id: 5, name: "Hybrid - Aggressive" },
  { id: 6, name: "Dyn Asset Alloc & Balance Adv" },
];

const other = [
  { id: 1, name: "ETFs - Equity & Index Fund" },
  { id: 2, name: "ETFs - Debt" },
  { id: 3, name: "FoFs - Equity" },
  { id: 4, name: "FoFs - Debt" },
  { id: 5, name: "FoFs-ETF-Gold" },
  { id: 6, name: "FoFs-ETF-Sliver" },
];

const solution = [
  { id: 1, name: "Retirement Fund" },
  { id: 2, name: "Children's Fund" },
];

module.exports = {
  ProductCode,
  BankName,
  PaymentMode,
  isholiday,
  Name_Days,
  STATE,
  FundData,
  equity,
  debt_funds,
  debit,
  hybrid,
  hybrid_funds,
  other,
  solution,
  redemptionVariables,
  purchaseVariables
};
